import { getItems, getItemsById } from '../../../api/index';
import rootTypes from '../../mutationTypes';
import types from './mutationTypes';

export default {
  async getLogs({ commit }) {
    commit(types.SET_LOADING, false);
    commit(types.SET_LOGS, await getItems('logs'));
    commit(types.SET_LOADING, true);
  },
  async getLogById({ commit }, id) {
    const currentLog = await getItemsById('logs', id);
    commit(types.SET_CURRENT_LOG, currentLog);
    commit(rootTypes.SET_ASSETS, 'all', { root: true });
  },
  updateLogInLogList({ state, commit }, log) {
    const logList = state.logs;
    const match = logList.find((p) => p._id === log._id);
    if (JSON.stringify(match) !== JSON.stringify(log)) {
      logList[logList.indexOf(match)] = log;
      commit(types.SET_LOGS, []);
      commit(types.SET_LOGS, logList);
    }
  },
  resetCurrentLog({ commit }) {
    commit(types.SET_CURRENT_LOG, {});
  },
};
