import types from './mutationTypes';

export default {
  resetFilters({ commit }) {
    commit(types.SET_SEARCH, {});
    commit(types.SET_TYPE, []);
    commit(types.SET_LOG_TYPE, []);
    commit(types.SET_CATEGORY, []);
    commit(types.SET_BRAND, []);
    commit(types.SET_MANUFACTURER, []);
    commit(types.SET_CHANNEL, []);
    commit(types.SET_ASSETS, {});
    commit(types.SET_UNIT, []);
    commit(types.SET_SHOW_UNPUBLISHED, true);
  },
  filterSearch({ commit }, val) {
    commit(types.SET_SEARCH, val);
  },
  filterType({ commit }, val) {
    commit(types.SET_TYPE, val);
  },
  filterLogType({ commit }, val) {
    commit(types.SET_LOG_TYPE, val);
  },
  filterCategory({ commit }, val) {
    commit(types.SET_CATEGORY, val);
  },
  filterBrand({ commit }, val) {
    commit(types.SET_BRAND, val);
  },
  filterManufacturer({ commit }, val) {
    commit(types.SET_MANUFACTURER, val);
  },
  filterChannel({ commit }, val) {
    commit(types.SET_CHANNEL, val);
  },
  filterAssets({ commit }, val) {
    commit(types.SET_ASSETS, val);
  },
  filterUnit({ commit }, val) {
    commit(types.SET_UNIT, val);
  },
  showUnpublished({ commit }, val) {
    commit(types.SET_SHOW_UNPUBLISHED, val);
  },
  setEditMode({ commit }, val) {
    commit(types.SET_EDIT_MODE, val);
  },
  setUnsavedChanges({ commit }, value) {
    commit(types.SET_UNSAVED_CHANGES, value);
  },
  showWarning({ commit }, context) {
    let resolvePromise;
    const promise = new Promise(((resolve) => {
      resolvePromise = resolve;
    }));
    commit(types.SET_WARNING, true);
    commit(types.SET_WARNING_CONTEXT, context);
    commit(types.SET_CONFIRMATION, resolvePromise);
    return promise;
  },
  hideWarning({ commit }) {
    commit(types.SET_CONFIRMATION, null);
    commit(types.SET_WARNING, false);
  },
  setSnackbar({ state, commit }, context) {
    commit(types.SET_SNACKBAR_CONTEXT, context);
    commit(types.SET_SNACKBAR, true);
    if (state.snackbarTimeout) {
      clearTimeout(state.snackbarTimeout);
    }
    commit(types.SET_SNACKBAR_TIMEOUT, setTimeout(() => {
      commit(types.SET_SNACKBAR, false);
      commit(types.SET_SNACKBAR_TIMEOUT, null);
    }, 4000));
  },
};
