export default {
  SET_SEARCH: 'SET_SEARCH',
  SET_TYPE: 'SET_TYPE',
  SET_LOG_TYPE: 'SET_LOG_TYPE',
  SET_CATEGORY: 'SET_CATEGORY',
  SET_BRAND: 'SET_BRAND',
  SET_MANUFACTURER: 'SET_MANUFACTURER',
  SET_CHANNEL: 'SET_CHANNEL',
  SET_ASSETS: 'SET_ASSETS',
  SET_UNIT: 'SET_UNIT',
  SET_SHOW_UNPUBLISHED: 'SET_SHOW_UNPUBLISHED',
  SET_EDIT_MODE: 'SET_EDIT_MODE',
  SET_UNSAVED_CHANGES: 'SET_UNSAVED_CHANGES',
  SET_CONFIRMATION: 'SET_CONFIRMATION',
  SET_WARNING: 'SET_WARNING',
  SET_WARNING_CONTEXT: 'SET_WARNING_CONTEXT',
  SET_SNACKBAR: 'SET_SNACKBAR',
  SET_SNACKBAR_TIMEOUT: 'SET_SNACKBAR_TIMEOUT',
  SET_SNACKBAR_CONTEXT: 'SET_SNACKBAR_CONTEXT',
};
