export default {
  allLogs: (state, getters, rootState) => {
    const filterLogs = state.logs.filter((i) => {
      // Filter by Type
      let type = rootState.filterLogType.includes(i.type);
      type = rootState.filterLogType.length > 0 ? type : true;
      return type;
    });

    let sortedLogs = filterLogs.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

    if (rootState.search.length > 0) {
      const qr = rootState.search.toLowerCase();
      sortedLogs = sortedLogs.filter((i) => i.email.toLowerCase().includes(qr)
      || i.item.toLowerCase().includes(qr));
    }

    return sortedLogs;
  },
  logCount: (state, getters) => getters.allLogs.length,
  currentLog: (state) => state.log,
  logTypes: (state) => [...new Set(state.logs.map((log) => log.type))],
  isLoaded: (state) => state.loading,
};
