import axios from 'axios';

export async function getItems(type) {
  try {
    const token = localStorage.getItem('jwt');
    const response = await axios.get(`/api/${type}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.documents;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        data: error.response.data,
      };
    }
    return {
      data: error.message,
    };
  }
}

export async function getItemsById(type, id) {
  try {
    const token = localStorage.getItem('jwt');
    const response = await axios.get(`/api/${type}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.document;
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        data: error.response.data,
      };
    }
    return {
      data: error.message,
    };
  }
}

export async function updatePublishStatus(type, id, status) {
  try {
    const token = localStorage.getItem('jwt');
    const response = await axios.patch(`/api/${type}/publish/${id}`, { published: status }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        data: error.response.data,
      };
    }
    return {
      data: error.message,
    };
  }
}

async function uploadImages(item, type) {
  try {
    const token = localStorage.getItem('jwt');
    const promises = [];
    const indexes = [];
    if (item.assets) {
      item.assets.forEach((asset, index) => {
        if (asset.raw) {
          indexes.push({ type: 'assets', index });
          promises.push(axios.post(`/api/images/?itemType=${type}&itemId=${item._id}&type=asset&name=${asset.name}&size=${asset.assetType}`, asset.raw, { headers: { Authorization: `Bearer ${token}` } }));
          delete item.assets[index].raw;
          delete item.assets[index].data;
        }
      });
    }
    if (item.cover && item.cover.raw) {
      indexes.push({ type: 'cover' });
      promises.push(axios.post(`/api/images/?itemType=${type}&itemId=${item._id}&type=cover`, item.cover.raw, { headers: { Authorization: `Bearer ${token}` } }));
      delete item.cover.raw;
      delete item.cover.data;
    }
    if (item.avatar && item.avatar.raw) {
      indexes.push({ type: 'avatar' });
      promises.push(axios.post(`/api/images/?itemType=${type}&itemId=${item._id}&type=avatar`, item.avatar.raw, { headers: { Authorization: `Bearer ${token}` } }));
      delete item.avatar.raw;
      delete item.avatar.data;
    }
    if (promises.length) {
      const resolved = await Promise.all(promises);
      resolved.forEach((response, index) => {
        if (response.status === 200) {
          const location = indexes[index];
          if (location.type === 'assets') {
            item.assets[location.index].url = `${process.env.VUE_APP_BUCKET_URL}/${response.data}`;
          } else {
            item[location.type].url = `${process.env.VUE_APP_BUCKET_URL}/${response.data}`;
          }
        } else {
          throw new Error(response.data);
        }
      });
    }
    return item;
  } catch (error) {
    return false;
  }
}

export async function createItem(type, item) {
  try {
    const token = localStorage.getItem('jwt');
    const newItem = await uploadImages(item, type);
    const response = await axios.post(`/api/${type}`, newItem, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        data: error.response.data,
      };
    }
    return {
      data: error.message,
    };
  }
}

export async function updateItem(type, id, item) {
  try {
    const token = localStorage.getItem('jwt');
    const newItem = await uploadImages(item, type);
    const response = await axios.patch(`/api/${type}/${id}`, newItem, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        data: error.response.data,
      };
    }
    return {
      data: error.message,
    };
  }
}

export async function deleteItem(type, id, imageKeys) {
  try {
    const token = localStorage.getItem('jwt');
    const response = await axios.delete(`/api/${type}/${id}`, {
      data: {
        imageKeys,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return {
      data: response.data,
      status: response.status,
    };
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        data: error.response.data,
      };
    }
    return {
      data: error.message,
    };
  }
}

export default {};
