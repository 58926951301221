import productCatalogue from './productCatalogue';
import logCatalogue from './logCatalogue';
import apiCatalogue from './apiCatalogue';
import auth from './auth';

export default {
  auth,
  productCatalogue,
  logCatalogue,
  apiCatalogue,
};
