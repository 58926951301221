import types from './mutationTypes';

export default {
  [types.SET_LOGS](state, payload) {
    state.logs = payload;
  },
  [types.SET_CURRENT_LOG](state, payload) {
    state.log = payload;
  },
  [types.SET_LOADING](state, payload) {
    state.loading = payload;
  },
};
