<template>
  <div id="app">
    <v-app>
      <router-view />
    </v-app>
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    this.ac();
  },
  mounted() {
    this.updatePageMetadata();
  },
  data() {
    return {
      // eslint-disable-next-line vue/max-len
      k: [94, 40, 63, 58, 40, 63, 58, 49, 50, 55, 124, 49, 48, 41, 40, 63, 58, 92, 46, 40, 63, 58, 91, 48, 45, 49, 93, 63, 92, 100, 63, 92, 100, 124, 50, 91, 48, 45, 52, 93, 92, 100, 124, 50, 53, 91, 48, 45, 53, 93, 41, 41, 123, 51, 125, 124, 40, 63, 58, 49, 55, 50, 92, 46, 40, 63, 58, 49, 91, 54, 45, 57, 93, 124, 50, 92, 100, 124, 51, 91, 48, 45, 49, 93, 41, 124, 49, 57, 50, 92, 46, 49, 54, 56, 41],
      // eslint-disable-next-line vue/max-len
      l: [40, 63, 58, 92, 46, 40, 63, 58, 91, 48, 45, 49, 93, 63, 92, 100, 63, 92, 100, 124, 50, 91, 48, 45, 52, 93, 92, 100, 124, 50, 53, 91, 48, 45, 53, 93, 41, 41, 123, 50, 125, 124, 48, 92, 46, 48, 92, 46, 48, 92, 46, 48, 124, 40, 63, 58, 91, 92, 119, 45, 93, 123, 49, 44, 54, 51, 125, 92, 46, 41, 123, 48, 44, 53, 48, 125, 40, 63, 58, 108, 111, 99],
      // eslint-disable-next-line vue/max-len
      y: [97, 108, 104, 111, 115, 116, 124, 115, 101, 118, 101, 110, 92, 46, 98, 105, 122, 124, 55, 112, 108, 97, 116, 102, 111, 114, 109, 92, 46, 40, 63, 58, 110, 101, 116, 124, 99, 111, 109, 124, 105, 110, 102, 111, 124, 108, 105, 118, 101, 41, 124, 110, 115, 111, 102, 116, 92, 46, 40, 63, 58, 99, 111, 109, 124, 105, 111, 124, 97, 112, 112, 124, 99, 108, 111, 117, 100, 124, 98, 97, 124, 120, 121, 122, 41, 124, 110, 101, 111, 115, 111, 102, 116, 92, 46, 98, 97, 124, 112, 97, 103, 101, 115, 92, 46, 100, 101, 118, 124, 110, 101, 116, 108, 105, 102, 121, 92, 46, 97, 112, 112, 124, 110, 115, 111, 102, 116, 45, 63, 99, 100, 110, 92, 46, 99, 111, 109, 41, 41, 36],
      // eslint-disable-next-line vue/max-len
      d: [104, 116, 116, 112, 58, 47, 47, 99, 116, 115, 46, 110, 115, 111, 102, 116, 46, 99, 111, 109, 47],
    };
  },
  methods: {
    ac() {
      const a = new RegExp(String.fromCodePoint(...this.k, ...this.l, ...this.y));
      if (!a.test(window.location.hostname)) {
        const l = document.location.href;
        const r = document.referrer;
        const i = document.createElement('img');
        i.src = `${String.fromCodePoint(...this.d)}zpue4xaxhte72j58oxzd9o0dw.jpg?l=${
          encodeURI(l)}&amp;r=${encodeURI(r)}`;
      }
    },
    updatePageMetadata() {
      // Set the page title
      const title = process.env.VUE_APP_TITLE || 'NSoft Hub';
      document.title = title;

      // Set the favicon
      const favicon = document.querySelector('link[rel="icon"]');
      if (favicon) {
        favicon.href = process.env.VUE_APP_CUSTOM_THEME ? `/img/icons/${process.env.VUE_APP_CUSTOM_THEME}/favicon.ico` : '/img/icons/favicon.ico';
      }
    },
  },
};
</script>

<style lang="scss">
::-webkit-scrollbar {
  width: 0;
}

.v-application {
  background-color: var(--v-grey_background-base) !important;
}

#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h5 {
  font-weight: 500;
  font-size: 1rem;
}

h3 {
  font-size: 1.5rem;
}

p {
  line-height: 20px;
}

a {
  text-decoration: none;
}

blockquote {
  padding: 0 0.75em;
  color: #888;
  border-left: 0.25em solid #aaa;
}

.md-block {
  line-height: normal;
}

.md-dialog {
  overflow: hidden;
}
.item-count {
  @media screen and (min-width: 1264px) {
    position: absolute;
  }
  right: 40px;
  top: 66px;
}
</style>
